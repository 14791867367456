import React, { useState } from 'react';
import newsuccess from 'assets/newsuccess.svg';
import CheckedIndicator from 'assets/physicalCard/Indicator.png';
import PendingIndicator from 'assets/physicalCard/pendingIndicator.png';
import { Platformbutton } from 'components/common/button/button';
import { FaCircleInfo } from 'react-icons/fa6';

export const ProgressPage = props => {
  const [progress, setProgress] = useState('Processing');
  // const status = 'NOT_SELECTED';
  // console.log('lottery status', props.lotteryStatus);
  switch (props.lotteryStatus) {
    case 'REGISTERED':
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '95%',
            gap: '10px',
            overflowY: 'auto',
          }}
          className="uploadSuccessPage"
        >
          <div className="d-flex flex-col mt-15" style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />
                <p>Submission</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                <img src={PendingIndicator} alt="" />

                <p>Processing</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                {/* <img src={PendingIndicator} alt="" /> */}
                <div
                  style={{
                    padding: '4px 12px',

                    border: '1px solid #98A2B3',
                    borderRadius: '100%',
                    background: '#F7F8FA',
                  }}
                >
                  3
                </div>
                <p>Result</p>
              </div>
            </div>

            <div
              className=" mx-auto "
              style={{
                marginTop: '30px',
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: '350px',
                gap: '20px',
              }}
            >
              <img src={newsuccess} alt="" />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1 style={{ fontSize: '20px', color: '#67A948' }}>
                  You have been entered into the pool
                </h1>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    color: '#2B5219',
                  }}
                >
                  You have been entered into the pool. Your status will be
                  changed accordingly.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    case 'SELECTED':
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '95%',
            gap: '10px',
            overflowY: 'auto',
          }}
          className="uploadSuccessPage"
        >
          <div className="d-flex flex-col mt-15" style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />
                <p>Submission</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />

                <p>Processing</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />

                <p>Result</p>
              </div>
            </div>

            <div
              className=" mx-auto "
              style={{
                marginTop: '30px',
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: '350px',
                gap: '20px',
              }}
            >
              <img src={newsuccess} alt="" />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1 style={{ fontSize: '20px', color: '#67A948' }}>
                  You have been selected in the pool{' '}
                </h1>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    color: '#2B5219',
                  }}
                >
                  Congratulations! You have gotten into the pool. Click on the
                  button below to begin your H1B Petition.
                </p>

                <div className="mt-4"></div>

                <Platformbutton
                  type="normal"
                  name="Begin Petition"
                  click={() => {}}
                  classname="fas fa-arrow-right"
                />
              </div>
            </div>
          </div>
        </div>
      );
    case 'NOT_SELECTED':
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '95%',
            gap: '10px',
            overflowY: 'auto',
          }}
          className="uploadSuccessPage"
        >
          <div className="d-flex flex-col mt-15" style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />
                <p>Submission</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />

                <p>Processing</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />

                <p>Result</p>
              </div>
            </div>

            <div
              className=" mx-auto "
              style={{
                marginTop: '30px',
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: '350px',
                gap: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <FaCircleInfo
                  style={{
                    color: '#67a948',
                    fontSize: '52px',
                    marginBottom: '10px',
                    marginTop: '30px',
                  }}
                />
                <h1 style={{ fontSize: '20px', color: '#67A948' }}>
                  You were not selected in the pool
                </h1>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    color: '#2B5219',
                  }}
                >
                  Oops sorry! Click on the button below to join waitlist.
                </p>

                <div className="mt-4"></div>

                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => {
                    window.open(
                      'https://wevesti.com/h1b-lottery-payment',
                      '_blank',
                    );
                  }}
                  classname="fas fa-arrow-right"
                />
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '95%',
            gap: '10px',
            overflowY: 'auto',
          }}
          className="uploadSuccessPage"
        >
          <div className="d-flex flex-col mt-15" style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <div className="GGSingleprogress">
                <img src={CheckedIndicator} alt="" />
                <p>Submission</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                <div
                  style={{
                    padding: '4px 12px',

                    border: '1px solid #98A2B3',
                    borderRadius: '100%',
                    background: '#F7F8FA',
                  }}
                >
                  2
                </div>

                <p>Processing</p>
              </div>

              <div className="GGcardPendingLines"></div>

              <div className="GGSingleprogress">
                {/* <img src={PendingIndicator} alt="" /> */}
                <div
                  style={{
                    padding: '4px 12px',

                    border: '1px solid #98A2B3',
                    borderRadius: '100%',
                    background: '#F7F8FA',
                  }}
                >
                  3
                </div>
                <p>Result</p>
              </div>
            </div>

            <div
              className=" mx-auto "
              style={{
                marginTop: '30px',
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: '350px',
                gap: '20px',
              }}
            >
              <img src={newsuccess} alt="" />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1 style={{ fontSize: '20px', color: '#67A948' }}>
                  Submitted to Vesti
                </h1>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    color: '#2B5219',
                  }}
                >
                  Your details have been submitted to Vesti and will soon be
                  entered into the lottery.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
  }
};
