import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { navigate } from '@reach/router';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { FiSearch } from 'react-icons/fi';
import { Largemodal, Simplemodal } from 'components/common/simplifiedmodal';
import visaBanner from 'assets/viasForm/visaprompt.svg';
import checkIcon from 'assets/viasForm/circle-check.svg';
import { Platformbutton } from 'components/common/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { handlevisaTypes } from 'appRedux/actions/visaForm';
import { logout } from 'assets/assets';
import './visatype.scss';
import img1 from 'assets/viasForm/o1visaSmall.svg';
import img2 from 'assets/viasForm/eb1small.svg';
import img3 from 'assets/viasForm/eb2small.svg';
import img4 from 'assets/viasForm/h1bsmall.svg';
import { Payment } from './payment';
import { fetchAllViasForms, handleSections } from 'appRedux/actions/visaForm';
import { SimpleCancelmodal } from 'components/common/simplifiedmodal';
import api from 'appRedux/api';
import { openUpdateBox } from 'appRedux/actions/update';
import { ImCheckboxChecked } from 'react-icons/im';
import { EligibilityTest } from './EligibilityTest/eligibility';
import h1bIcon from 'assets/popupModalIcons/H1bpopup.svg';
import { radio_card, radio_ngn } from 'assets/assets';
import pathwayconfig from 'config.js';
import {
  RadioOption,
  VerySmallRadioOption,
} from 'components/common/radiobutton/radiobutton';
// 0 - 1, EB - one, EB - two, H1B;
const pathwayVisa = [
  {
    abbr: '0-1',
    title: 'U.S. 0-1 Visa',
    text: 'Individuals with Extraordinary Ability or Achievement',
    buttonText: 'Start Application',
    visaType: '0-1',
    url: '0-1visa',
    img: img1,
    progress: 40,
    payment: 'FULLY PAID',
  },

  {
    abbr: 'EB-1',
    title: 'U.S. EB-1 Visa',
    text: 'Employment Based Green Card Visa (Extraordinary Ability)',
    buttonText: 'Start Application',
    visaType: 'EB-1',
    url: 'EB-1visa',
    img: img2,
    progress: 20,
    payment: 'FULLY PAID',
  },

  {
    abbr: 'EB-2',
    title: 'U.S. EB-2 NIW Visa',
    text: 'Employment Based Green Card (National Interest Waiver)',
    buttonText: 'Start Application',
    visaType: 'EB-2 NIW',
    url: 'EB-2NIWvisa',
    img: img3,
    progress: 0,
    payment: 'FULLY PAID',
  },

  {
    abbr: 'H1B',
    title: 'U.S. H1B Visa',
    text: 'Foreign workers with specialized skills',
    buttonText: 'Start Application',
    visaType: 'H1B',
    url: 'H1Bvisa',
    img: img4,
    progress: 0,
    payment: 'UNPAID',
  },

  {
    abbr: 'F1',
    title: 'U.S. F1 Visa',
    text: 'United States Study Visa',
    buttonText: 'Start Application',
    visaType: 'F1',
    url: 'F1visa',
    img: img4,
    progress: 0,
    payment: 'UNPAID',
  },

  {
    abbr: 'UkTechNation',
    title: 'UK Tech Nation Visa',
    text: 'UK Tech Nation',
    buttonText: 'Start Application',
    visaType: 'UkTechNation',
    url: 'UkTechNationvisa',
    img: img4,
    progress: 0,
    payment: 'UNPAID',
  },

  {
    abbr: 'Canadian-Caregiver',
    title: 'Canadian Caregiver',
    text: 'Individuals with Qualifications in Caregiving.',
    buttonText: 'Start Application',
    visaType: 'Canadian Caregiver',
    url: 'Canadian-Caregivervisa',
    img: img4,
    progress: 0,
    payment: 'UNPAID',
  },

  {
    abbr: 'B1/B2',
    title: 'U.S. B-1 / B-2 Visa',
    text: 'Business & Tourism Visa',
    buttonText: 'Start Application',
    visaType: 'B-1/B-2',
    url: 'B-1B-2visa',
    img: img4,
    progress: 0,
    payment: '25% PAID',
  },
];

export const Visatypes = () => {
  const userD = useSelector(state => state.auth?.userData);

  const [visareqModal, setVisaReqModal] = useState(false);
  const [h1bvisareqModal, setH1bVisaReqModal] = useState(false);
  const [reqAandBModal, setReqAandBModal] = useState(false);

  const [paymentModal, setPaymentModal] = useState(false);
  const [visatitle, setVisaTile] = useState('');
  const [visaLink, setVisaLink] = useState('');
  const [vType, setVType] = useState('');
  const [vReqType, setVreqType] = useState('');
  const [amountPaid, setAmountPaid] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [searchResult, setSearchResult] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();
  const handleSearchChange = event => {
    const value = event.target.value;
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(fetchAllViasForms(userD?.id));
  }, [userD]);

  const applyFilter = () => {
    if (searchValue.trim() === '') {
      setFilteredData(pathwayVisa);
      setSearchResult('');
    } else {
      const filteredTitles = pathwayVisa?.filter(visa =>
        visa.abbr.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setSearchResult(filteredTitles.length > 0 ? '' : 'Visa not found');
      setFilteredData(filteredTitles);
    }
  };

  useEffect(() => {
    applyFilter();
  }, [searchValue]);

  const [matchingItems, setMatchingItems] = useState([]);
  const [nonMatchingItems, setNonMatchingItems] = useState([]);

  const Forms = useSelector(state => state.visaForm.submittedForms);

  const [formId, setFormId] = useState('');

  useEffect(() => {
    const foundItems = Forms
      ? Forms?.filter(item =>
          pathwayVisa.map(({ visaType }) => visaType).includes(item.visaType),
        )
      : [];

    const nonMatchingItems = Forms
      ? pathwayVisa?.filter(
          visa =>
            !Forms?.map(({ visaType }) => visaType).includes(visa.visaType),
        )
      : [];

    if (foundItems.length === 0 && nonMatchingItems.length === 0) {
      // console.log('No matching or non-matching objects found.');
      setMatchingItems([]);
      setNonMatchingItems([]);
    } else {
      // console.log('Matching objects:', foundItems);
      // console.log('Non-matching objects:', nonMatchingItems);
      setMatchingItems(foundItems);
      setNonMatchingItems(nonMatchingItems);
    }
  }, [Forms]);

  const [isChecked, setIsChecked] = useState(false);
  const [isH1bModal, setIsH1bModal] = useState(false);
  const [delayModal, setDelayModal] = useState(false);

  //  const [passportModal, setPassportModal] = useState(false);
  // add
  useEffect(() => {
    const notification = userD?.notification?.h1bLotteryNotification;

    if (notification === false) {
      setIsH1bModal(true);
    } else if (notification === true) {
      setIsH1bModal(false);
    } else if (notification === undefined && userD?.notification) {
      setIsH1bModal(true);
    } else {
      setIsH1bModal(false);
    }
  }, [userD]);

  const handleh1BPopUp = async () => {
    setIsChecked(!isChecked);

    const payload = {
      key: 'h1bLotteryNotification',
      value: true,
    };

    api
      .patch('customer/notification', payload)
      .then(res => {
        setIsH1bModal(false);
      })
      .catch(err => {
        console.error('Error:', err);
        setIsH1bModal(false);
      });
  };

  const handleActionH1b = async () => {
    var url = `${pathwayconfig.baseURL}/pathway/submit-visa-documents`;
    const formData = new FormData();

    formData.append('email', userD?.email);
    formData.append('userId', userD?.id);
    formData.append('firstName', userD?.firstName);
    formData.append('visaType', 'H1B');

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      dispatch(handlevisaTypes(visatitle, vType));

      navigate(`/pathways/H1Bvisa`);
      handleh1BPopUp();
    } catch (error) {
      console.error('Error creating file:', error);
    }
  };

  return (
    <Layout>
      <Simplemodal onClick={() => setIsH1bModal(false)} open={isH1bModal}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 20px',
          }}
        >
          <img src={h1bIcon} alt="" />

          <div>
            <h1
              style={{
                color: '#67A948',
                fontWeight: '500',
                fontSize: '18px',
                margin: '20px 0px 10px 0px',
                textAlign: 'center',
              }}
            >
              Move to the US as an H-1B Worker with minimum HND/Bachelors Degree{' '}
            </h1>
            <p
              style={{
                color: '#2B5219',
                fontWeight: '500',
                fontSize: '13px',
                margin: '0px 0px 20px 0px',
                textAlign: 'center',
              }}
            >
              The pre-registration for H1B lottery registration for 2026 has now
              opened.{' '}
            </p>

            <div style={{ marginTop: '1rem' }}></div>

            <Platformbutton
              name="Start now"
              type="normal"
              click={() => {
                handleActionH1b();
              }}
            />

            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <ImCheckboxChecked
                style={{
                  background: 'white',
                  color: isChecked ? '#518C36' : 'white',
                  cursor: 'pointer',
                  border: '1px solid #518C36',
                  width: '14px',
                  height: '14px',
                  borderRadius: '2px',
                }}
                onClick={() => handleh1BPopUp()}
              />{' '}
              <p style={{ color: '#2B5219' }}>Don't remind me again</p>
            </div>
          </div>
        </div>
      </Simplemodal>

      <div
        style={{ height: '85svh', overflowY: 'auto' }}
        className="mainvisaTypesSection"
      >
        <p
          onClick={() => {
            navigate('/pathways');
          }}
          style={{
            display: 'flex',
            color: '#67A948',
            alignItems: 'center',
            gap: '5px',
            fontWeight: '600',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          <FaArrowLeftLong /> Pathways
        </p>

        <div style={{ marginTop: '1rem' }}>
          <p
            className=" text-[#14290A] "
            style={{ fontWeight: '600', fontSize: '24px' }}
          >
            Select your Visa Type{' '}
          </p>
        </div>

        <div
          style={{
            background: '#fff',
            padding: '10px',
            color: '#858CA0',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            gap: '15px 10px',
            fontSize: '16px',
            marginTop: '1rem',
            marginBottom: '1rem',
            borderRadius: '10px',
          }}
          className="VisainputContainer"
        >
          {' '}
          <FiSearch />
          <input
            type="text"
            placeholder=" Search for visa type"
            value={searchValue}
            onChange={handleSearchChange}
            className="usInputField"
          />
        </div>

        <div>
          <EligibilityTest />
        </div>

        <div className="visaTypes">
          {filteredData?.map(
            ({
              abbr,
              title,
              text,
              buttonText,
              url,
              visaType,
              img,
              progress,
              payment,
            }) => {
              return (
                <div key={title}>
                  <Mycard
                    abbr={abbr}
                    title={title}
                    text={text}
                    url={url}
                    buttonText={buttonText}
                    setVisaReqModal={setVisaReqModal}
                    visareqModal={visareqModal}
                    visatitle={visatitle}
                    setVisaTile={setVisaTile}
                    setVisaLink={setVisaLink}
                    visaLink={visaLink}
                    setVType={setVType}
                    visaType={visaType}
                    vType={vType}
                    img={img}
                    progress={progress}
                    payment={payment}
                    setPaymentModal={setPaymentModal}
                    matchingItems={matchingItems}
                    nonMatchingItems={nonMatchingItems}
                    formId={formId}
                    setFormId={setFormId}
                    setAmountPaid={setAmountPaid}
                    amountPaid={amountPaid}
                    isSubmitted={isSubmitted}
                    setIsSubmitted={setIsSubmitted}
                    setH1bVisaReqModal={setH1bVisaReqModal}
                    h1bvisareqModal={h1bvisareqModal}
                    reqAandBModal={reqAandBModal}
                    setReqAandBModal={setReqAandBModal}
                    vReqType={vReqType}
                    setVreqType={setVType}
                  />
                </div>
              );
            },
          )}
        </div>

        <p className="mt-2 text-red-600 text-center">{searchResult}</p>
      </div>
      <SimpleCancelmodal
        open={visareqModal}
        onClick={() => setVisaReqModal(false)}
      >
        <VisaRequirementModal
          visatitle={visatitle}
          visaLink={visaLink}
          vType={vType}
          setVisaReqModal={setVisaReqModal}
          setPaymentModal={setPaymentModal}
        />
        {/* <Payment /> */}
      </SimpleCancelmodal>

      <SimpleCancelmodal
        open={h1bvisareqModal}
        onClick={() => setH1bVisaReqModal(false)}
      >
        <H1BVisaRequirementModal
          visatitle={visatitle}
          visaLink={visaLink}
          vType={vType}
          formId={formId}
          setH1bVisaReqModal={setH1bVisaReqModal}
        />
      </SimpleCancelmodal>

      <SimpleCancelmodal
        open={reqAandBModal}
        onClick={() => setReqAandBModal(false)}
      >
        <reqAandBModal
          visatitle={visatitle}
          visaLink={visaLink}
          vType={vType}
          setVisaReqModal={setVisaReqModal}
          setPaymentModal={setPaymentModal}
          vReqType={vReqType}
          setVreqType={setVreqType}
        />
      </SimpleCancelmodal>

      <SimpleCancelmodal
        open={paymentModal}
        onClick={() => setPaymentModal(false)}
      >
        <Payment
          visatitle={visatitle}
          visaLink={visaLink}
          vType={vType}
          formId={formId}
          amountPaid={amountPaid}
          setPaymentModal={setPaymentModal}
          paymentModal={paymentModal}
          setVisaReqModal={setVisaReqModal}
          vReqType={vReqType}
          setVreqType={setVreqType}
        />
      </SimpleCancelmodal>
    </Layout>
  );
};

const Items = [
  { title: 'Resume & Credentials' },
  { title: 'Evidence of Employment with Filing Company (Your Company)' },
  { title: 'Employment in Critical Capacity' },
  { title: 'Recommendation Letters' },
  { title: 'Scholarly Articles, or Evidence of Authorship' },
  { title: 'Evidence of Judging the work of others' },
  { title: 'Awards and Award Nominations' },
  { title: 'Memberships' },
  { title: 'Newspaper Publications' },
  { title: 'Evidence of High Salary' },
  { title: 'Itinerary' },
  { title: 'Immigration Documents' },
];

const Mycard = props => {
  const dispatch = useDispatch();
  const [kycModal, setKycModal] = useState(false);
  const userD = useSelector(state => state.auth?.userData);

  // console.log(('My New User Data', userD?.verifiedKyc));

  // var checkKyc = () => {
  //   userD?.verifiedKyc === 'APPROVED' || userD?.verifiedKyc === true
  //     ? setKycModal(false)
  //     : ;
  // };

  return (
    <div>
      <div
        className="visatypecard"
        style={{
          background: `${
            props.abbr === '0-1'
              ? // || props.abbr === 'B1/B2'
                '#EDFDFF'
              : props.abbr === 'EB-1' || props.abbr === 'H1B'
              ? //  : props.abbr === 'EB-1' || props.abbr === 'F1'
                '#FFF9F0'
              : props.abbr === 'EB-2'
              ? '#F6F7FF'
              : '#F9FAFB'
          }`,
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          border: '1px solid #EAEAEA',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {/* <img
              src={props.img}
              alt=""
              style={{ width: '24px', height: '24px' }}
            /> */}

            <p
              style={{
                color: `${
                  props.abbr === '0-1'
                    ? // || props.abbr === 'B1/B2'
                      '#195742'
                    : // props.abbr === 'EB-1' || props.abbr === 'F1'
                    // ? '#936710'
                    props.abbr === 'EB-1' || props.abbr === 'H1B'
                    ? '#936710'
                    : props.abbr === 'EB-2'
                    ? '#060E42'
                    : '#667085'
                }`,
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              {props.title}
            </p>
          </div>

          {props.nonMatchingItems.map(item =>
            item.visaType === props.visaType ? (
              <p
                style={{
                  color: `${
                    props.abbr === '0-1'
                      ? // || props.abbr === 'B1/B2'
                        '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? // props.abbr === 'EB-1' || props.abbr === 'F1'
                        '#936710'
                      : props.abbr === 'EB-2'
                      ? '#060E42'
                      : '#667085'
                  }`,
                  fontSize: '11px',
                  border: `0.5px solid ${
                    props.abbr === '0-1'
                      ? '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? '#936710'
                      : '#060E42'
                  }`,
                  padding: '2px 10px',
                  borderRadius: '5px',
                  fontWeight: '600',
                }}
              >
                {props.abbr === '0-1' ||
                props.abbr === 'EB-1' ||
                props.abbr === 'H1B' ||
                props.abbr === 'EB-2'
                  ? 'UNPAID'
                  : 'COMING SOON'}{' '}
              </p>
            ) : (
              <></>
            ),
          )}

          {props.matchingItems.map(item =>
            item.visaType === props.visaType ? (
              <p
                style={{
                  color: `${
                    props.abbr === '0-1'
                      ? // || props.abbr === 'B1/B2'
                        '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? //  || props.abbr === 'F1'
                        '#936710'
                      : props.abbr === 'EB-2'
                      ? '#060E42'
                      : '#667085'
                  }`,
                  fontSize: '11px',
                  border: `0.5px solid ${
                    props.abbr === '0-1'
                      ? '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? '#936710'
                      : '#060E42'
                  }`,
                  padding: '2px 10px',
                  borderRadius: '5px',
                  fontWeight: '600',
                }}
              >
                {item.paymentPercent === null || item.paymentPercent == 0 ? (
                  'UNPAID'
                ) : item.paymentPercent == 100 ? (
                  'Fully Paid'
                ) : (
                  <>{item.paymentPercent} % paid</>
                )}
              </p>
            ) : (
              <></>
            ),
          )}
        </div>
        <p
          style={{
            color: `${
              props.abbr === '0-1'
                ? // || props.abbr === 'B1/B2'
                  '#195742'
                : props.abbr === 'EB-1' || props.abbr === 'H1B'
                ? //  || props.abbr === 'F1'
                  '#936710'
                : props.abbr === 'EB-2'
                ? '#303D94'
                : '#667085'
            }`,
            fontWeight: '600',
          }}
        >
          {props.text}
        </p>

        {props.nonMatchingItems.map(item =>
          item.visaType === props.visaType ? (
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '4px',
                  height: '5px',
                }}
              >
                <div
                  style={{
                    width: `${0}%`,
                    height: '100%',
                    backgroundColor: `${
                      props.abbr === '0-1'
                        ? '#3B9B7B'
                        : props.abbr === 'EB-1' || props.abbr === 'H1B'
                        ? '#BB8211'
                        : '#303D94'
                    }`,
                    borderRadius: '4px',
                    transition: 'width 0.1s ease-in-out',
                  }}
                ></div>
              </div>
              <p
                style={{
                  fontSize: '14px',
                }}
              >{`${0}%`}</p>
            </div>
          ) : (
            <></>
          ),
        )}

        {props.matchingItems.map(item =>
          item.visaType === props.visaType ? (
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '4px',
                  height: '5px',
                }}
              >
                <div
                  style={{
                    width: `${
                      item.fileUploadPercentage === null
                        ? 0
                        : item.fileUploadPercentage
                    }%`,
                    height: '100%',
                    backgroundColor: `${
                      props.abbr === '0-1'
                        ? '#3B9B7B'
                        : props.abbr === 'EB-1' || props.abbr === 'H1B'
                        ? '#BB8211'
                        : '#303D94'
                    }`,
                    borderRadius: '4px',
                    transition: 'width 0.1s ease-in-out',
                  }}
                ></div>
              </div>
              <p
                style={{
                  fontSize: '14px',
                }}
              >{`${
                item.fileUploadPercentage === null
                  ? 0
                  : item.fileUploadPercentage
              }%`}</p>
            </div>
          ) : (
            <></>
          ),
        )}

        {props.matchingItems.map(item =>
          item.visaType === props.visaType ? (
            props.abbr === '0-1' ||
            props.abbr === 'EB-1' ||
            props.abbr === 'H1B' ||
            props.abbr === 'EB-2' ? (
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: `${
                    props.abbr === '0-1'
                      ? // || props.abbr === 'B1/B2'
                        '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? // || props.abbr === 'F1'
                        '#936710'
                      : props.abbr === 'EB-2'
                      ? '#303D94'
                      : '#667085'
                  }`,
                  fontSize: '14px',
                  fontWeight: '600',
                  cursor: 'pointer',
                }}
                // {item.submitted ? onClick={() => {
                //   dispatch(handlevisaTypes(props.visatitle, props.vType));
                //   navigate(`/pathways/${props.visaLink}`);
                // }} :

                onClick={
                  // userD?.verifiedKyc !== 'APPROVED' ||
                  // userD?.verifiedKyc !== true
                  //   ? () => {
                  //       dispatch(openUpdateBox());
                  //     }
                  //   :
                  item.submitted
                    ? () => {
                        dispatch(handlevisaTypes(props.title, props.visaType));
                        navigate(`/pathways/${props.url}`);
                      }
                    : () => {
                        props.setVisaTile(props.title);
                        props.setVisaLink(props.url);
                        props.setVType(props.visaType);
                        props.setFormId(item.id);
                        props.setIsSubmitted(item.submitted);

                        item.amountPaid
                          ? props.setAmountPaid(item.amountPaid)
                          : props.setAmountPaid(0);

                        item.paymentPercent === null ||
                        (item.paymentPercent == 0 && props.abbr !== 'H1B')
                          ? props.setPaymentModal(true)
                          : props.abbr === 'H1B'
                          ? props.setH1bVisaReqModal(true)
                          : props.abbr == '0-1'
                          ? props.setReqAandBModal(true)
                          : props.setVisaReqModal(true);
                      }
                }
              >
                {item.submitted
                  ? 'View Document'
                  : !item.submitted && item.fileUploadPercentage > 0
                  ? 'Resume Application'
                  : props.buttonText}{' '}
                <FaArrowRightLong />
              </p>
            ) : (
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: `${
                    props.abbr === '0-1'
                      ? // || props.abbr === 'B1/B2'
                        '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? //  || props.abbr === 'F1'
                        '#936710'
                      : props.abbr === 'EB-2'
                      ? '#303D94'
                      : '#667085'
                  }`,
                  fontSize: '14px',
                  fontWeight: '600',
                  cursor: 'not-allowed',
                }}
                //  {props.abbr === '0-1'?
                onClick={() => {}}
              >
                {props.buttonText} <FaArrowRightLong />
              </p>
            )
          ) : (
            <></>
          ),
        )}

        {props.nonMatchingItems.map(item =>
          item.visaType === props.visaType ? (
            props.abbr === '0-1' ||
            props.abbr === 'EB-1' ||
            props.abbr === 'H1B' ||
            props.abbr === 'EB-2' ? (
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: `${
                    props.abbr === '0-1'
                      ? // || props.abbr === 'B1/B2'
                        '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? // props.abbr === 'EB-1' || props.abbr === 'F1'
                        '#936710'
                      : props.abbr === 'EB-2'
                      ? '#303D94'
                      : '#667085'
                  }`,
                  fontSize: '14px',
                  fontWeight: '600',
                  cursor: 'pointer',
                }}
                onClick={
                  // userD?.verifiedKyc !== 'APPROVED' ||
                  // userD?.verifiedKyc !== true
                  //   ? () => {
                  //       dispatch(openUpdateBox());
                  //     }
                  //   :
                  () => {
                    props.setVisaTile(props.title);
                    props.setVisaLink(props.url);
                    props.setVType(props.visaType);
                    props.setFormId('');
                    // props.setPaymentModal(true);

                    props.abbr !== 'H1B'
                      ? props.setPaymentModal(true)
                      : props.abbr === 'H1B'
                      ? props.setH1bVisaReqModal(true)
                      : // props.setVisaReqModal(true);
                      props.abbr == '0-1'
                      ? props.setReqAandBModal(true)
                      : props.setVisaReqModal(true);
                  }
                }
              >
                {props.buttonText} <FaArrowRightLong />
              </p>
            ) : (
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: `${
                    props.abbr === '0-1'
                      ? // || props.abbr === 'B1/B2'
                        '#195742'
                      : props.abbr === 'EB-1' || props.abbr === 'H1B'
                      ? // props.abbr === 'EB-1' || props.abbr === 'F1'
                        '#936710'
                      : props.abbr === 'EB-2'
                      ? '#303D94'
                      : '#667085'
                  }`,
                  fontSize: '14px',
                  fontWeight: '600',
                  cursor: 'not-allowed',
                }}
                onClick={() => {}}
              >
                {props.buttonText} <FaArrowRightLong />
              </p>
            )
          ) : (
            <></>
          ),
        )}
      </div>
    </div>
  );
};

export const VisaRequirementModal = props => {
  const dispatch = useDispatch();

  return (
    <div style={{ width: '100%' }}>
      <img
        src={visaBanner}
        alt=""
        style={{
          position: 'absolute',
          width: '100%',
          left: '0px',
          top: '0px',
          right: '0px',
          height: '13rem',
        }}
      />

      {/* <div className="visaMobilewanrning">
        <h1
          style={{
            color: '#67A948',
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Switch to desktop
        </h1>
        <p style={{ fontSize: '14px', color: '#14290A', textAlign: 'center' }}>
          This feature is optimized for desktop. Try switching views for a
          smoother experience!
        </p>
      </div> */}
      {/* </div> */}
      <div className="VisarequirementsArticle" style={{ marginBottom: '1rem' }}>
        <h1 style={{ color: '#14290A', fontSize: '20px', fontWeight: '600' }}>
          Submit your {props.visatitle} requirements
        </h1>
        <p style={{ fontSize: '13px', fontWeight: '600', color: '#14290A' }}>
          Before you proceed you need to take notice of the following things:
        </p>
      </div>

      <div className="mobilehidden">
        <ul
          style={{
            color: '#2B5219',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
            <img src={checkIcon} alt="" />
            This form is strictly for people applying for an {props.visatitle}.
            Do not proceed if you are not applying for an {props.visatitle}{' '}
            petition.
          </li>

          <li style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
            <img src={checkIcon} alt="" />
            Screenshots and photographs of documents are generally not
            admissible by USCIS. Please upload very clear scanned copies of all
            documents.
          </li>

          {/* <li style={{ display: 'flex', gap: '5px' }}>
            <img src={checkIcon} alt="" />
            Form accept only upload in PDF format.
           
          </li> */}
        </ul>
      </div>
      <p
        style={{ color: '#518C36', fontWeight: '500' }}
        className="mobilehidden"
      >
        NB: You can save your progress and continue uploading your files at your
        leisure before the deadline.
      </p>

      <div className="mt-4 mobilehidden" style={{ marginTop: '1rem' }}>
        <Platformbutton
          type="normal"
          name={'Proceed'}
          click={() => {
            dispatch(handlevisaTypes(props.visatitle, props.vType));
            // navigate(`/pathways/${props.visaLink}`);
            props.vType === '0-1'
              ? navigate(`/pathways/${props.vReqType}`)
              : navigate(`/pathways/${props.visaLink}`);
          }}
        />
      </div>

      {/* <div className="mt-4 visaMobilewanrningbtn" style={{ marginTop: '1rem' }}>
        <Platformbutton
          type="normal"
          name={'Okay, thank you'}
          disabled={false}
          click={() => {
            props.setVisaReqModal(false);
            props.setPaymentModal(false);
          }}
        />
      </div> */}
    </div>
  );
};

export const VisaReqAandBModal = props => {
  // const dispatch = useDispatch();
  const setVisaReqType = value => {
    props.setVreqType(value);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <h1
          style={{
            color: '#14290A',
            fontSize: '20px',
            fontWeight: '600',
            // textAlign: 'center',
          }}
        >
          O-1 VISA
        </h1>

        <p style={{ fontSize: '14px', color: '#2B5219' }}>
          Before proceeding, identify which O-1 visa category fits your
          qualifications:
        </p>
      </div>
      {/* </div> */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <RadioOption
          image={radio_ngn}
          changed={setVisaReqType}
          id="1"
          isSelected={props.vReqType === '0-1A'}
          label={'O-1A Visa'}
          sublabel="For individuals with extraordinary ability in the sciences, education, business, or athletics."
          value="0-1A"
        />
        <RadioOption
          image={radio_ngn}
          changed={setVisaReqType}
          id="2"
          isSelected={props.vReqType === '0-1B'}
          label={'O-1B Visa'}
          sublabel="For individuals with extraordinary ability or in the arts, motion picture or television industry."
          value="0-1B"
        />
      </div>

      <div className="mt-4 " style={{ marginTop: '1rem' }}>
        <Platformbutton
          type="normal"
          name={'Continue'}
          disabled={!props.vReqType ? true : false}
          click={() => {
            props.click();
            // dispatch(handlevisaTypes(props.visatitle, props.vType));
            // navigate(`/pathways/${props.visaLink}`);
          }}
        />
      </div>
    </div>
  );
};

export const H1BVisaRequirementModal = props => {
  const userData = useSelector(state => state.auth?.userData);

  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const createFormAndContinue = async () => {
    var url = `${pathwayconfig.baseURL}/pathway/submit-visa-documents`;
    const formData = new FormData();

    formData.append('email', userData?.email);
    formData.append('firstName', userData?.firstName);
    formData.append('userId', userData?.id);
    formData.append('visaType', 'H1B');

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      dispatch(handlevisaTypes(props.visatitle, props.vType));
      navigate(`/pathways/${props.visaLink}`);
    } catch (error) {
      console.error('Error creating file:', error);
    }
  };

  const continueWithId = async () => {
    dispatch(handlevisaTypes(props.visatitle, props.vType));
    navigate(`/pathways/${props.visaLink}`);
  };

  return (
    <div style={{ width: '100%' }}>
      <img
        src={visaBanner}
        alt=""
        style={{
          position: 'absolute',
          width: '100%',
          left: '0px',
          top: '0px',
          right: '0px',
          height: '13rem',
        }}
      />

      {/* <div className="visaMobilewanrning">
        <h1
          style={{
            color: '#67A948',
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Switch to desktop
        </h1>
        <p style={{ fontSize: '14px', color: '#14290A', textAlign: 'center' }}>
          This feature is optimized for desktop. Try switching views for a
          smoother experience!
        </p>
      </div> */}

      <div className="" style={{ marginBottom: '1rem', marginTop: '13rem' }}>
        <h1 style={{ color: '#14290A', fontSize: '20px', fontWeight: '600' }}>
          Apply for the H1-B Lottery
        </h1>
        <p style={{ fontSize: '13px', fontWeight: '600', color: '#14290A' }}>
          Before you proceed you need to take notice of the following things:{' '}
        </p>
      </div>

      <div className="">
        <ul
          style={{
            color: '#2B5219',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <li style={{ color: '#518C36', fontWeight: '500' }}>
            This is the first stage of your H1-B Lottery registration. This is
            not an application for the H1-B Visa
          </li>

          <li
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'flex-start',
              fontSize: '13px',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '4px' }} />
            Please enter your details as written on your International passport.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
              fontSize: '13px',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '4px' }} />
            If you do not complete payment, you will not be entered into the
            lottery.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
              fontSize: '13px',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '4px' }} />
            None of the listed employers are liable for any errors in your
            registration.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
              fontSize: '13px',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '4px' }} />
            <span>
              Communication for this process is only through Vesti's official
              email
              <span style={{ color: '#518C36' }}> info@wevesti.com.</span>
            </span>
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
              fontSize: '14px',
            }}
          >
            <img src={checkIcon} alt="" />
            You will be able watch a clarity session once your payment is
            completed.
          </li>
        </ul>
      </div>

      <div className="">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <div style={{}}>
            <ImCheckboxChecked
              style={{
                background: 'white',
                color: isChecked ? '#518C36' : 'white',
                cursor: 'pointer',
                border: '1px solid #518C36',
                width: '12px',
                height: '12px',
              }}
              onClick={() => handleCheckboxChange()}
            />
          </div>
          <p style={{ color: '#2B5219', fontSize: '14px' }}>
            I agree to Vesti’s{' '}
            <span
              style={{ color: '#67A948', cursor: 'pointer' }}
              onClick={() => {
                window.open(' https://wevesti.com/tos', '_blank');
              }}
            >
              Terms & Conditions.
            </span>
          </p>
        </div>
      </div>

      <div className="mt-4 " style={{ marginTop: '1rem' }}>
        <Platformbutton
          type="normal"
          name={'Proceed'}
          disabled={isChecked ? false : true}
          click={() => {
            props.formId ? continueWithId() : createFormAndContinue();
          }}
        />
      </div>

      {/* <div className="mt-4 visaMobilewanrningbtn" style={{ marginTop: '1rem' }}>
        <Platformbutton
          type="normal"
          name={'Okay, thank you'}
          disabled={false}
          click={() => {
            props.setH1bVisaReqModal(false);
            props.setPaymentModal(false);
          }}
        />
      </div> */}
    </div>
  );
};
