import React, { useEffect, useState } from 'react';
import { Section1 } from './section1';
import { useDispatch, useSelector } from 'react-redux';
import { fetchViasForms } from 'appRedux/actions/visaForm';
import { updateUploadedFiles, handleSections } from 'appRedux/actions/visaForm';
import Inputfloat from 'components/common/inputs/inputfloat';
import './section.scss';
import { NewInputFloat } from 'components/common/inputs/inputfloat';
import {
  BigIconOption,
  BigIconSingleValue,
  CustomizedSingleselect,
} from 'components/common/inputs/singleselect';
import covenLogo from 'assets/viasForm/covenlabsFullLogo.svg';
import AsraLogo from 'assets/viasForm/asraFullLogo.png';
import { NewTextarea } from 'components/common/inputs/textarea';
import { Platformbutton } from 'components/common/button/button';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import pathwayconfig from 'config.js';
import { MdOutlineArrowOutward } from 'react-icons/md';
import { SimpleCancelmodal } from 'components/common/simplifiedmodal';
import { ImCheckboxChecked } from 'react-icons/im';
import h1bIcon from 'assets/popupModalIcons/H1bpopup.svg';
import api from 'appRedux/api';
export const Stage2Section = props => {
  const [visaType, setVisaType] = useState('');
  const storedvisaType = useSelector(state => state.visaForm.visaAbbr);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Forms = useSelector(state => state.visaForm.submittedForms);
  const userD = useSelector(state => state.auth?.userData);
  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');
  const [showModal, setShowModal] = useState(false);
  // useEffect(() => {
  //   if (splitLocation[2] === '0-1visa' && !storedvisaType) {
  //     setVisaType('0-1');
  //   } else if (splitLocation[2] === 'EB-1visa' && !storedvisaType) {
  //     setVisaType('EB-1');
  //   } else if (splitLocation[2] === 'EB-2NIWvisa' && !storedvisaType) {
  //     setVisaType('EB-2 NIW');
  //   } else if (splitLocation[2] === 'H1Bvisa' && !storedvisaType) {
  //     setVisaType('H1B');
  //   } else if (splitLocation[2] === 'B-1B-2visa' && !storedvisaType) {
  //     setVisaType('B-1/B-2');
  //   } else if (splitLocation[2] === 'F1visa' && !storedvisaType) {
  //     setVisaType('F1');
  //   } else if (storedvisaType) {
  //     setVisaType(storedvisaType);
  //   }
  // }, []);

  // const uploadedFiles = useSelector(state => state.visaForm.uploadedFiles);

  //       dispatch(updateUploadedFiles({}));

  // useEffect(() => {
  //   dispatch(fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName));
  // }, []);

  const [state, setState] = useState({
    fullName: '',
    // middleName: '',
    // lastName: '',
    dob: '',
    degree: '',
    bio: '',
    nameAndDobofDependant: '',
    fieldOfStudy: '',
    credentialevaluation: '',
    advancedDegree: '',
    advancedDegreeType: '',
    nameOfcurrentEmployer: '',
    currentJob: '',
    address: '',
    visaInterviewLocation: '',
    phoneNumber: '',
    // phoneNumber: '',
  });

  //  console.log(state);

  var setInput = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  var setDOBInput = e => {
    const { name, value } = e.target;
    // Remove any non-numeric characters
    const cleaned = value.replace(/\D/g, '');
    // Ensure the length is not more than 6 characters
    const truncated = cleaned.substring(0, 6);
    // Insert '/' after every 2 numbers
    const formatted = truncated
      .replace(/(\d{2})(\d{0,2})/, '$1/$2')
      .replace(/(\d{2})\/(\d{2})(\d{0,2})/, '$1/$2/$3');
    setState({ ...state, [name]: formatted });
  };

  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  //  var handleSelect = value => {
  //    setState({ ...state, value });

  //  };

  const [option, setOption] = useState(null);
  const setMyOption = value => {
    setOption(value);
  };

  var selectOpt = [{ opt: 'Yes' }, { opt: 'No' }];
  var selectfillingCompany = [
    { opt: 'Coven Works', icon: covenLogo },
    { opt: 'Asra', icon: AsraLogo },
    { opt: 'I have company filing for me', icon: '' },
  ];

  const updateApplication = async () => {
    // setSelectedFiles([{}, {}]);
    // props.setLoading(true);
    // setButtonText('Saving...');

    var url = `${pathwayconfig.baseURL}/pathway/update-visa-documents`;

    const formData = new FormData();

    formData.append('email', userD?.email);
    formData.append('id', Forms[0]?.id);
    formData.append('userId', userD?.id);
    formData.append('firstName', userD?.firstName);

    // formData.append('firstName', state.firstName);
    // formData.append('lastName', state.lastName);
    // formData.append('middleName', state.middleName);
    if (state.fullName) {
      formData.append('fullname', state.fullName);
    }
    if (state.dob) {
      formData.append('dateOfBirth', state.dob);
    }
    if (state.bio) {
      formData.append('aboutYourself', state.bio);
    }
    if (state.nameAndDobofDependant) {
      formData.append('dependantsInformations', state.nameAndDobofDependant);
    }
    if (state.advancedDegree) {
      formData.append(
        'hasAdvanceDegree',
        state.advancedDegree === 'Yes' ? true : false,
      );
    }
    if (state.advancedDegreeType) {
      formData.append('advanceDegreeDetails', state.advancedDegreeType);
    }
    if (state.fieldOfStudy) {
      formData.append('fieldOfStudy', state.fieldOfStudy);
    }
    if (state.degree) {
      formData.append(
        'hasDegreeTranscript',
        state.degree === 'Yes' ? true : false,
      );
    }
    if (state.credentialevaluation) {
      formData.append(
        'hasCredentialsEvaluation',
        state.credentialevaluation === 'Yes' ? true : false,
      );
    }
    if (state.nameOfcurrentEmployer) {
      formData.append('nameOfCurrentEmployer', state.nameOfcurrentEmployer);
    }
    if (state.currentJob) {
      formData.append('nameOfCurrentJobTitle', state.currentJob);
    }
    if (state.visaInterviewLocation) {
      formData.append('visaInterviewLocation', state.visaInterviewLocation);
    }
    if (state.address) {
      formData.append('homeAddress', state.address);
    }
    if (state.phoneNumber) {
      formData.append('phoneNumber', state.phoneNumber);
    }

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      props.setLoading(false);

      dispatch(updateUploadedFiles(null));

      // setButtonText('Save section');

      setState({
        fullName: '',
        dob: '',
        degree: '',
        bio: '',
        nameAndDobofDependant: '',
        fieldOfStudy: '',
        credentialevaluation: '',
        advancedDegree: '',
        advancedDegreeType: '',
        nameOfcurrentEmployer: '',
        currentJob: '',
        address: '',
        visaInterviewLocation: '',
        phoneNumber: '',
      });

      // dispatch(
      //   fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName),
      // );
      openNotificationWithIcon('', 'Saved');
      dispatch(
        fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName),
      );
    } catch (error) {
      dispatch(
        fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName),
      );
      openNotificationWithIcon('', 'Saved');
      console.error('Error uploading file:', error);
      // setButtonText('Save section');
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isH1bModal, setIsH1bModal] = useState(false);
  const [delayModal, setDelayModal] = useState(false);

  //  const [passportModal, setPassportModal] = useState(false);
  // add
  useEffect(() => {
    const notification = userD?.notification?.h1bLotteryNotificationStage2;

    if (notification === false) {
      setIsH1bModal(true);
    } else if (notification === true) {
      setIsH1bModal(false);
    } else if (notification === undefined && userD?.notification) {
      setIsH1bModal(true);
    } else {
      setIsH1bModal(false);
    }
  }, [userD]);

  const handleh1BPopUp = async () => {
    setIsChecked(!isChecked);

    const payload = {
      key: 'h1bLotteryNotificationStage2',
      value: true,
    };

    api
      .patch('customer/notification', payload)
      .then(res => {
        setIsH1bModal(false);
      })
      .catch(err => {
        console.error('Error:', err);
        setIsH1bModal(false);
      });
  };

  // console.log(Forms[0]?.hasDegreeTranscript);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <SimpleCancelmodal open={isH1bModal} onClick={() => setIsH1bModal(false)}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 20px',
            }}
          >
            <img src={h1bIcon} alt="" />

            <div>
              <h1
                style={{
                  color: '#67A948',
                  fontWeight: '500',
                  fontSize: '18px',
                  margin: '20px 0px 10px 0px',
                  textAlign: 'center',
                }}
              >
                U.S. H1B Petition Stage 2
              </h1>
              <p
                style={{
                  color: '#2B5219',
                  fontWeight: '500',
                  fontSize: '14px',
                  margin: '0px 0px 20px 0px',
                  textAlign: 'center',
                }}
              >
                For seeing the H1B stage two page, you have been selected in the
                lottery and you have received an email concerning next steps.
                You will not be able submit this form if you haven't paid for
                the cost of the stage two petition. Ask your case officer for an
                invoice. If you have paid, contact your case officer if you have
                difficulty filling this form.
              </p>

              <div style={{ marginTop: '1rem' }}></div>

              <Platformbutton
                name="Okay, thank you"
                type="normal"
                click={() => {
                  setIsH1bModal(false);
                }}
              />

              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <ImCheckboxChecked
                  style={{
                    background: 'white',
                    color: isChecked ? '#518C36' : 'white',
                    cursor: 'pointer',
                    border: '1px solid #518C36',
                    width: '14px',
                    height: '14px',
                    borderRadius: '2px',
                  }}
                  onClick={() => handleh1BPopUp()}
                />{' '}
                <p style={{ color: '#2B5219' }}>Don't remind me again</p>
              </div>
            </div>
          </div>
        </div>
      </SimpleCancelmodal>
      {/* {Forms[0]?.afterSubmittionStatus !== 'completed_stage2' ? ( */}
      <>
        {' '}
        <div className="flex w-full justify-between items-center">
          <div>
            <p
              style={{
                color: '#67A948',
                fontWeight: '600',
                fontSize: '18px',
              }}
            >
              Personal Information Section{' '}
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              Fill in this form accurately with up to date information.
            </p>
          </div>
          {/* <div
            onClick={() => {
              setIsH1bModal(true);
            }}
            className=""
            style={{
              fontSize: '14px',
              display: 'flex',
              cursor: 'pointer',
              color: '#67a948',
              fontWeight: '600',
              alignItems: 'center',
              gap: '4px',
              marginRight: '10px',
            }}
          >
            More Info <MdOutlineArrowOutward />
          </div> */}
        </div>
        <div className="h1bInputsection mt-6">
          <Inputfloat
            type="text"
            name="fullName"
            label="FULL NAME (AS WRITTEN ON YOUR PASSPORT)"
            value={
              state.fullName
                ? state.fullName
                : Forms[0]?.visaInterviewLocation && Forms[0]?.fullname
                ? Forms[0]?.fullname
                : state.fullName
            }
            disabled={false}
            placeholder={`${'FULL NAME'}`}
            onChange={setInput}
          />

          <Inputfloat
            type="text"
            name="dob"
            label="DATE OF BIRTH (DD/MM/YY)"
            value={state.dob ? state.dob : Forms[0]?.dateOfBirth}
            disabled={false}
            placeholder={`${'DD/MM/YY'}`}
            onChange={setDOBInput}
          />
        </div>
        <div>
          <NewTextarea
            type="text"
            label="Tell us a little about yourself and your career history (In less than 100 words only)"
            name="bio"
            placeholder={`${'Tell us a little about yourself and your career history (In less than 100 words only)'}`}
            value={state.bio ? state.bio : Forms[0]?.aboutYourself}
            disabled={false}
            onChange={setInput}
            text="Tell us a little about yourself and your career history (In less 100 words only)"
          />

          <div style={{ marginBottom: '20px' }}></div>
          <NewTextarea
            type="text"
            label="Names and Dates of Birth of Dependents (Wife/Husband and Children)- IF ANY. Please type N/A if you don't have a husband/wife or children"
            name="nameAndDobofDependant"
            placeholder={`${'Names and Dates of Birth of Dependents (Wife/Husband and Children)'}`}
            value={
              state.nameAndDobofDependant
                ? state.nameAndDobofDependant
                : Forms[0]?.dependantsInformations
            }
            disabled={false}
            onChange={setInput}
            text="Names and Dates of Birth of Dependents (Wife/Husband and Children)"
          />
        </div>
        <div className="h1bInputsection ">
          <Inputfloat
            type="text"
            name="fieldOfStudy"
            label="Field of Study (Bachelors Degree/HND)"
            value={
              state.fieldOfStudy ? state.fieldOfStudy : Forms[0]?.fieldOfStudy
            }
            disabled={false}
            placeholder={`${'BSc. Accounting'}`}
            onChange={setInput}
          />

          <CustomizedSingleselect
            value={
              state.degree || !Forms[0]?.fieldOfStudy
                ? state.degree
                : Forms[0]?.hasDegreeTranscript == true
                ? 'Yes'
                : 'No'
            }
            options={selectOpt.map((op, index) => ({
              key: index,
              label: op.opt,
              value: op.opt,
            }))}
            placeholder={`${"Do you have your Bachelor's degree or HND certificate and transcript?"}`}
            onChange={selectedValue => {
              handleSelect('degree', selectedValue);
            }}
            name="Do you have your Bachelor's degree or HND certificate and transcript?"
          />
        </div>
        <div>
          <CustomizedSingleselect
            value={
              state.credentialevaluation || !Forms[0]?.fieldOfStudy
                ? state.credentialevaluation
                : Forms[0]?.hasCredentialsEvaluation == true
                ? 'Yes'
                : 'No'
            }
            options={selectOpt.map((op, index) => ({
              key: index,
              label: op.opt,
              value: op.opt,
            }))}
            placeholder={`${"Have you ever done the credential evaluation for your Bachelor's Degree/HND to show U.S Equivalence? (E.g through WES, IQAS or any other recognized evaluation body?)"}`}
            // placeholder=""
            onChange={selectedValue => {
              handleSelect('credentialevaluation', selectedValue);
            }}
            name="Have you ever done the credential evaluation for your Bachelor's Degree/HND to show U.S Equivalence? (E.g through WES, IQAS or any other recognized evaluation body?)"
          />
        </div>
        <div className="h1bInputsection ">
          <CustomizedSingleselect
            value={
              state.advancedDegree || !Forms[0]?.fieldOfStudy
                ? state.advancedDegree
                : Forms[0]?.hasAdvanceDegree == true
                ? 'Yes'
                : 'No'
            }
            options={selectOpt.map((op, index) => ({
              key: index,
              label: op.opt,
              value: op.opt,
            }))}
            placeholder={`${'Do You Have any advanced degree like a Masters or PHD?'}`}
            onChange={selectedValue => {
              handleSelect('advancedDegree', selectedValue);
            }}
            name="Do You Have any advanced degree like a Masters or PHD?"
          />

          <Inputfloat
            type="text"
            name="advancedDegreeType"
            label="If YES please specify and what field"
            value={
              state.advancedDegreeType
                ? state.advancedDegreeType
                : Forms[0]?.advanceDegreeDetails
            }
            disabled={false}
            placeholder={`${'If YES please specify whether Masters or PHD, and what field'}`}
            // placeholder="If YES please specify whether Masters or PHD, and what field"
            onChange={setInput}
          />
        </div>
        <div className="h1bInputsection">
          <Inputfloat
            type="text"
            name="nameOfcurrentEmployer"
            label="Name of Current Employer (your current job in the country you currently live) "
            value={
              state.nameOfcurrentEmployer
                ? state.nameOfcurrentEmployer
                : Forms[0]?.nameOfCurrentEmployer
            }
            disabled={false}
            placeholder={`${'Name of Current Employer'}`}
            onChange={setInput}
          />
          <Inputfloat
            type="text"
            name="currentJob"
            label="Current Job Title or Role"
            value={
              state.currentJob
                ? state.currentJob
                : Forms[0]?.nameOfCurrentJobTitle
            }
            disabled={false}
            placeholder={`${'Current Job Title or Role'}`}
            // placeholder="Current Job Title or Role"
            onChange={setInput}
          />
          {/* {Forms[0]?.visaInterviewLocation ? (
            <></>
          ) : ( */}
          <Inputfloat
            type="text"
            name="address"
            label="Home Address (Where you currently live)"
            value={state.address ? state.address : Forms[0]?.homeAddress}
            disabled={false}
            placeholder="Home Address (Where you currently live)"
            onChange={setInput}
          />

          {/* <Inputfloat
            type="text"
            name="phoneNumber"
            label="Phone Number"
            value={
              state.phoneNumber ? state.phoneNumber : Forms[0]?.phoneNumber
            }
            disabled={false}
            placeholder="+2348118675465"
            onChange={setInput}
          /> */}

          <Inputfloat
            type="text"
            name="visaInterviewLocation"
            label="Visa Interview Location"
            value={
              state.visaInterviewLocation
                ? state.visaInterviewLocation
                : Forms[0]?.visaInterviewLocation
            }
            placeholder={`${'Visa Interview Location'}`}
            disabled={false}
            onChange={setInput}
          />

          <Inputfloat
            type="text"
            name="phonenumber"
            label="Phone Number"
            value={
              state.phoneNumber ? state.phoneNumber : Forms[0]?.phoneNumber
            }
            placeholder={`${'+2348118675645'}`}
            disabled={false}
            onChange={setInput}
          />
        </div>
        {Forms[0]?.afterSubmittionStatus == 'completed_stage2' ? (
          <></>
        ) : (
          <div
            style={{
              margin: '1rem 0px 0.25rem 0px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div></div>

            <p
              style={{
                padding: '15px 25px',
                background: `${
                  state.fullName ||
                  // !state.middleName ||
                  // !state.lastName ||
                  state.dob ||
                  state.degree ||
                  state.bio ||
                  state.nameAndDobofDependant ||
                  state.fieldOfStudy ||
                  state.credentialevaluation ||
                  state.advancedDegree ||
                  // state.advancedDegreeType ||
                  state.nameOfcurrentEmployer ||
                  state.currentJob ||
                  state.address ||
                  state.visaInterviewLocation ||
                  state.phoneNumber
                    ? 'green'
                    : '#4D5366'
                }`,
                color: 'white',
                fontSize: '14px',
                fontWeight: '600',
                marginTop: '2rem',
                borderRadius: '10px',
                cursor: `${
                  state.fullName ||
                  // !state.middleName ||
                  // !state.lastName ||
                  state.dob ||
                  state.degree ||
                  state.bio ||
                  state.nameAndDobofDependant ||
                  state.fieldOfStudy ||
                  state.credentialevaluation ||
                  state.advancedDegree ||
                  // state.advancedDegreeType ||
                  state.nameOfcurrentEmployer ||
                  state.currentJob ||
                  state.address ||
                  state.visaInterviewLocation ||
                  state.phoneNumber
                    ? // Forms[0]?.dependantsInformations
                      'pointer'
                    : 'not-allowed'
                }`,
              }}
              onClick={() => {
                updateApplication();
                // props.Forms[0]?.id
                //   ?
                //   : submitApplication();
              }}
            >
              Save Section
            </p>
          </div>
        )}
      </>
      <div style={{ marginTop: '2rem' }}>
        <p style={{ color: '#67A948', fontWeight: '600', fontSize: '18px' }}>
          File Upload Section{' '}
        </p>
        <p style={{ color: '#2B5219', fontSize: '13px', fontWeight: '600' }}>
          Fill in this form accurately with up to date information.
        </p>
      </div>
      {/* Demmmyyyyyyyyyyyyyyyyy helppppppp */}
      <Section1
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={props.loading}
        setLoading={props.setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
        handleSections={handleSections}
        isClarityVideoChecked={props.isClarityVideoChecked}
        state={state}
      />
    </div>
  );
};
